import { useEffect, useRef, useState } from "react";
import MainLayout from "./../../Layout/MainLayout";
import CustomCard from "../../Components/CustomCard";

import BASEURL from "./../../Config/global";

import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
// import { playAudio } from "../../Store/Slices/AudioSlice";
import { playSound, playAudio, clearAllSound, checkSoundPlayerOrMixes, pauseMyMixes, clearAllMixer, playMyMixer, playMyMixerObj } from "../../Store/Slices/SoundPlayerSlice";

import { activeUser, userLogout } from "../../Store/Slices/APIs";
import { GetAPI } from "../../Api/ApiConfig";
import { crossIcon } from "../../Assets/svg";
import { decryptUserId } from "../../Services/Common";

import "./style.css";

import { pauseMixer } from "../../Store/Slices/MixerSlice";
import { ExternalMix } from "../../Store/Slices/MyMixerSlice";
import { Modal } from "react-bootstrap";
import Common from "../../Util/common";

const Home = () => {

  let { id } = useParams();
  const userPrompts = useSelector((state) => state.user.userPrompts);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const searchField = useRef('null')

  const [searchQuery, setSearchQuery] = useState('');
  const [soundData, setSoundData] = useState([]);
  const [meditationData, setMeditationData] = useState([]);
  const [storiesData, setStoriesData] = useState([]);
  const [articlesData, setArticleData] = useState([]);
  const [featuredMix, setFeaturedMix] = useState([]);
  const [_isSharedMixDeleted, setSharedMixDeleted] = useState(false);
  const [errorMessageForMixture, setErrorMessageForMixture] = useState(false);
  const [greetingMessage, setGreetingMessage] = useState("Welcome");

  const checkSoundPlayerOrMixesState = useSelector((state) => state.soundPlayer.checkSoundPlayerOrMixesState);

  useEffect(() => {
    dispatch(userLogout())
  }, []);

  useEffect(() => {
    if (id) {
      getDataByApi();
    }
  }, [id, userPrompts?.emptymix_age_restriction_notification]);

  const getDataByApi = async () => {
    try {
      const userId = decryptUserId(id);
      const response = await axios.get(`${BASEURL}/api/sounds/sounds_mixture/${userId}`);
      const data = response.data.data[0]
      if (data?.sounds_list?.length === 0) {
        setSharedMixDeleted(true);
        setErrorMessageForMixture(userPrompts?.emptymix_age_restriction_notification || "There is nothing to play for this mix.")
        return
      }
      dispatch(playMyMixer(data.sounds_list));
      dispatch(playMyMixerObj(data));
      dispatch(ExternalMix(data.sounds_list));
      setTimeout(() => {
        dispatch(pauseMixer());
      }, 100);
    } catch (error) {
      setErrorMessageForMixture("This Mix has been deleted by the sender.")
      setSharedMixDeleted(true);
      console.log(error)
    }
  }

  const getHomePageData = async (query = null) => {
    try {
      let apiUrl = '/api/articles/all-data/', catApiUrl = '/api/user/coupon_cat_sub_cat?promo_id=', cat_res;
      if (query) {
        apiUrl += `?search=${query}`
      }
      const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
      const res = await GetAPI(apiUrl);
      if (user?.promo_id) {
        cat_res = await GetAPI(catApiUrl + user?.promo_id)
      }
      // console.log(cat_res)
      if (!res?.data?.error) {
        // For promo users only!
        const cats_and_subcats = cat_res?.data?.data;
        if (cats_and_subcats && cats_and_subcats?.['cats_and_subcats'] && !cats_and_subcats['is_new'] && user?.promo_id) {
          // Featured Mixes Categories
          const featured_mixes_cat = cat_res['data']['data']['cats_and_subcats']['featured_mixes'];
          const featured_mixes_cat_set = new Set(
            featured_mixes_cat?.flatMap(({ category, subcategories }) =>
              [category, ...subcategories].filter(Boolean) // Remove null/undefined values
            ) || []
          );
          const filteredSoundsMixture = res?.data?.data?.SoundsMixture?.filter(
            item => featured_mixes_cat_set.has(item.featured_category) &&
              (!item.sub_category || featured_mixes_cat_set.has(item.sub_category))
          ) ?? [];
          setFeaturedMix(filteredSoundsMixture || []);

          // Articles Categories
          const article_cat = cat_res['data']['data']['cats_and_subcats']['article'];
          const article_cat_set = new Set(
            article_cat?.flatMap(({ category, subcategories }) =>
              [category, ...subcategories].filter(Boolean) // Remove null/undefined values
            ) || []
          );
          const filteredArticles = res?.data?.data?.Articles?.filter(
            item => article_cat_set.has(item.articlecategory) &&
              (!item?.subcategory_obj?.child_subcategory_id || article_cat_set.has(item?.subcategory_obj?.child_subcategory_id))
          ) ?? [];
          setArticleData(filteredArticles || []);

          // Sounds Categories
          const sound_cat = cat_res['data']['data']['cats_and_subcats']['sound'];
          const sound_cat_set = new Set(
            sound_cat?.flatMap(({ category, subcategories }) =>
              [category, ...subcategories].filter(Boolean) // Remove null/undefined values
            ) || []
          );
          const filteredSounds = res?.data?.data?.Sounds?.filter(
            item => sound_cat_set.has(item.soundcategory) &&
              (!item?.subcategory_obj?.child_subcategory_id || sound_cat_set.has(item?.subcategory_obj?.child_subcategory_id))
          ) ?? [];
          setSoundData(filteredSounds || []);

          // Meditation Categories
          const meditation_cat = cat_res['data']['data']['cats_and_subcats']['meditation'];
          const meditation_cat_set = new Set(
            meditation_cat?.flatMap(({ category, subcategories }) =>
              [category, ...subcategories].filter(Boolean) // Remove null/undefined values
            ) || []
          );
          const filteredMediations = res?.data?.data?.Mediations?.filter(
            item => meditation_cat_set.has(item.meditationcategory) &&
              (!item?.subcategory_obj?.child_subcategory_id || meditation_cat_set.has(item?.subcategory_obj?.child_subcategory_id))
          ) ?? [];
          setMeditationData(filteredMediations || []);

          // Stories Categories
          const stories_cat = cat_res['data']['data']['cats_and_subcats']['stories'];
          const stories_cat_set = new Set(
            stories_cat?.flatMap(({ category, subcategories }) =>
              [category, ...subcategories].filter(Boolean) // Remove null/undefined values
            ) || []
          );
          const filteredStories = res?.data?.data?.Stories?.filter(
            item => stories_cat_set.has(item.storiescategory) &&
              (!item?.subcategory_obj?.child_subcategory_id || stories_cat_set.has(item?.subcategory_obj?.child_subcategory_id))
          ) ?? [];
          setStoriesData(filteredStories || []);
        } else {
          const { Articles, Mediations, Sounds, Stories, SoundsMixture } = res.data.data;
          setArticleData(Articles || []);
          setMeditationData(Mediations || []);
          setSoundData(Sounds || []);
          setStoriesData(Stories || []);
          setFeaturedMix(SoundsMixture || []);
        }

      } else {
        console.log("something is wrong", res.data.message)
      }
    } catch (error) {
      console.log("something is wrong", error)
    }


    // if (!response?.data?.error) {
    // } else {

    // }
    // if (user?.coupon && user?.coupon?.id) {
    //   const cat_url = `/api/user/coupon_cat_sub_cat?promo_id=${user?.coupon?.id}`
    //   const cat_res = await GetAPI(cat_url);


    //   if (response?.data?.data) {
    //     if (cat_res['data']['data']['cats_and_subcats']) {
    //       // const article_cat = cat_res['data']['data']['cats_and_subcats']['article'];
    //       // const sound_cat = cat_res['data']['data']['cats_and_subcats']['sound'];
    //       // const meditation_cat = cat_res['data']['data']['cats_and_subcats']['meditation'];
    //       // const stories_cat = cat_res['data']['data']['cats_and_subcats']['stories'];

    //       // const article_cat_set = new Set(article_cat.flatMap(item => [item.category, item.subcategories]).filter(Boolean) || []);
    //       // const sound_cat_set = new Set(sound_cat.flatMap(item => [item.category, item.subcategories]).filter(Boolean) || []);
    //       // const meditation_cat_set = new Set(meditation_cat.flatMap(item => [item.category, item.subcategories]).filter(Boolean) || []);
    //       // const stories_cat_set = new Set(stories_cat.flatMap(item => [item.category, item.subcategories]).filter(Boolean) || []);

    //       // const filteredArticles = response?.data?.data['Articles'].filter(item => article_cat_set.has(item.articlecategory || item?.subcategory_obj?.child_subcategory_id));
    //       // const filteredSounds = response?.data?.data['Sounds'].filter(item => sound_cat_set.has(item.soundcategory || item?.subcategory_obj?.child_subcategory_id));
    //       // const filteredMediations = response?.data?.data['Mediations'].filter(item => meditation_cat_set.has(item.meditationcategory || item?.subcategory_obj?.child_subcategory_id));
    //       // const filteredStories = response?.data?.data['Stories'].filter(item => stories_cat_set.has(item.storiescategory || item?.subcategory_obj?.child_subcategory_id));

    //       // console.log(filteredSounds)
    //       // setSoundData(filteredSounds || []);
    //       // setMeditationData(filteredMediations || []);
    //       // setStoriesData(filteredStories || []);
    //     } else {
    //       const { Articles, Mediations, Sounds, Stories, SoundsMixture } = response.data.data;
    //       setArticleData(Articles || []);
    //       setMeditationData(Mediations);
    //       setSoundData(Sounds);
    //       setStoriesData(Stories);
    //       setFeaturedMix(SoundsMixture);
    //     }
    //   }

    // } else {
    //   const res = await GetAPI(apiUrl);
    //   const { error } = res.data;
    //   if (error) {
    //     console.log("something is wrong", res.data.message)
    //     return
    //   }
    //   if (res.data && res.data.data) {
    //     const { Articles, Mediations, Sounds, Stories, SoundsMixture } = res.data.data
    //     setArticleData(Articles || []);
    //     setMeditationData(Mediations);
    //     setSoundData(Sounds);
    //     setStoriesData(Stories);
    //     setFeaturedMix(SoundsMixture);
    //   }

    // }
  }

  useEffect(() => {
    getHomePageData();
  }, []);

  useEffect(() => {
    var myDate = new Date();
    var hrs = myDate.getHours();
    if (hrs > 5 && hrs < 12)
      setGreetingMessage('Good Morning');
    else if (hrs >= 12 && hrs < 17)
      setGreetingMessage('Good Afternoon');
    else if (hrs >= 17 && hrs <= 20)
      setGreetingMessage('Good Evening');
    else if (hrs > 20 && hrs <= 24 || hrs < 5)
      setGreetingMessage('Good Night');
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getHomePageData(searchQuery);
    }, 500);

    // Cleanup timeout when the component unmounts or when searchQuery changes
    return () => clearTimeout(timeoutId);

  }, [searchQuery]);

  const dispatchPlaySound = (item) => {
    if (!checkSoundPlayerOrMixesState) {
      dispatch(clearAllSound());
    }
    dispatch(checkSoundPlayerOrMixes(true));
    dispatch(clearAllMixer());
    dispatch(playSound(item));
  };

  const dispatchPlayAudio = (item) => {
    if (!checkSoundPlayerOrMixesState) {
      dispatch(clearAllSound());
    }
    dispatch(checkSoundPlayerOrMixes(true));
    dispatch(playAudio(item));
  };

  // const notify = () => {
  //   toast('🦄 Wow so easy!', {
  //     position: "top-right",
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "light",
  //     transition: Bounce,
  //     type: "error"
  //   });
  // };

  return (
    <>
      <MainLayout title={greetingMessage}>
        <div className="search-bar mb-4 position-relative">
          <input
            type="search"
            className={`searchBar w-100 pe-5`}
            placeholder="What do you want to listen to?"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            ref={searchField}
            onFocus={() => searchField.current.placeholder = ""}
            onBlur={() => searchField.current.placeholder = "What do you want to listen to?"}
          />
          {
            searchQuery.length > 0 && (
              <button
                className="closeButton notButton position-absolute end-0 top-0 px-3 py-2"
                onClick={() => setSearchQuery('')}
              >
                <img src={crossIcon} alt="" />
              </button>
            )
          }
        </div>
        <div className="row mb-3 home-page-row">
          {
            (searchQuery != '' && featuredMix.length == 0) ? '' : (
              <>
                <div className="col-6">
                  <h2 className="sectionTitle">Featured mixes</h2>
                </div>
                <div className="col-6 text-end">
                  <Link
                    to={"/featured-mixes"}
                    className="smallText goldenColor2 text-decoration-none d-flex align-items-center justify-content-end ms-auto"
                  >
                    Browse Collection
                    <svg xmlns='http://www.w3.org/2000/svg' width={'15px'} viewBox='0 0 16 16' fill='#f7ba31' style={{ transform: 'rotate(270deg)' }}>
                      <path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' />
                    </svg>
                  </Link>
                </div>
              </>
            )
          }
          <div className="col-12">
            <div className="homeboxesWrapper">
              {/* <button onClick={notify}>Notify!</button>
              <ToastContainer /> */}
              {
                featuredMix.slice(0, 20).map((item, index) => (
                  // Render your original data items here
                  <CustomCard
                    key={index}
                    title={item.title}
                    image={`${item.banner}`}
                    alt={"In The News"}
                    onClick={() => {
                      dispatchPlaySound(item);
                    }}
                    item={item}
                    type={Common.FEATUREDMIXES}
                  />
                ))
              }
            </div>
          </div>
        </div>
        <div className="row mb-3 home-page-row">
          {
            (searchQuery != '' && soundData.length == 0) ? '' : (
              <>
                <div className="col-6">
                  <h2 className="sectionTitle">Sounds & Music</h2>
                </div>
                <div className="col-6 text-end">
                  <Link
                    to={"/sounds"}
                    className="smallText goldenColor2 text-decoration-none d-flex align-items-center justify-content-end ms-auto"
                  >
                    Browse Collection
                    <svg xmlns='http://www.w3.org/2000/svg' width={'15px'} viewBox='0 0 16 16' fill='#f7ba31' style={{ transform: 'rotate(270deg)' }}>
                      <path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' />
                    </svg>
                  </Link>
                </div>
              </>
            )
          }
          <div className="col-12">
            <div className="homeboxesWrapper">
              {
                soundData.slice(0, 20).map((item, index) => (
                  // Render your original data items here
                  <CustomCard
                    key={index}
                    title={item.title}
                    image={`${item.image}`}
                    alt={"In The News"}
                    onClick={() => {
                      dispatchPlaySound(item);
                    }}
                    item={item}
                  />
                ))
              }
            </div>
          </div>
        </div>
        <div className="row mb-3 home-page-row">
          {
            (searchQuery != '' && meditationData.length == 0) ? '' : (
              <>
                <div className="col-6">
                  <h2 className="sectionTitle">{Common.RELAXANDREST}</h2>
                </div>
                <div className="col-6 text-end">
                  <Link
                    to={"/relax-rest"}
                    className="smallText goldenColor2 text-decoration-none d-flex align-items-center justify-content-end ms-auto"
                  >
                    Browse Collection
                    <svg xmlns='http://www.w3.org/2000/svg' width={'15px'} viewBox='0 0 16 16' fill='#f7ba31' style={{ transform: 'rotate(270deg)' }}>
                      <path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' />
                    </svg>
                  </Link>
                </div>
              </>
            )
          }
          <div className="col-12">
            <div className="homeboxesWrapper">
              {
                meditationData.slice(0, 20).map((item, index) => (
                  <CustomCard
                    key={index}
                    title={item.title}
                    image={`${item.image}`}
                    alt={"relaxation"}
                    onClick={() => {
                      dispatchPlayAudio(item);
                    }}
                    item={item}
                  />
                ))
              }

            </div>
          </div>
        </div>
        <div className="row mb-3 home-page-row">
          {
            (searchQuery != '' && storiesData.length == 0) ? '' : (
              <>
                <div className="col-6">
                  <h2 className="sectionTitle">Stories</h2>
                </div>
                <div className="col-6 text-end">
                  <Link
                    to={"/stories"}
                    className="smallText goldenColor2 text-decoration-none d-flex align-items-center justify-content-end ms-auto"
                  >
                    Browse Collection
                    <svg xmlns='http://www.w3.org/2000/svg' width={'15px'} viewBox='0 0 16 16' fill='#f7ba31' style={{ transform: 'rotate(270deg)' }}>
                      <path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' />
                    </svg>
                  </Link>
                </div>
              </>
            )
          }
          <div className="col-12">
            <div className="homeboxesWrapper">

              {
                storiesData.slice(0, 20).map((item, index) => (
                  <CustomCard
                    key={index}
                    title={item.title}
                    image={`${item.image}`}
                    alt={"Stories"}
                    onClick={() => {
                      dispatchPlayAudio(item);
                    }}
                    item={item}
                  />
                )
                )}
            </div>
          </div>
        </div>
        <div className="row mb-3 home-page-row">
          {
            (searchQuery != '' && articlesData.length == 0) ? '' : (
              <>
                <div className="col-6">
                  <h2 className="sectionTitle">Audio Articles</h2>
                </div>
                <div className="col-6 text-end">
                  <Link
                    to={"/articles"}
                    className="smallText goldenColor2 text-decoration-none d-flex align-items-center justify-content-end ms-auto"
                  >
                    Browse Collection
                    <svg xmlns='http://www.w3.org/2000/svg' width={'15px'} viewBox='0 0 16 16' fill='#f7ba31' style={{ transform: 'rotate(270deg)' }}>
                      <path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' />
                    </svg>
                  </Link>
                </div>
              </>
            )
          }
          <div className="col-12">
            <div className="homeboxesWrapper">
              {

                articlesData.slice(0, 20).map((item, index) => (
                  <CustomCard
                    key={index}
                    title={item.title}
                    image={`${item.image}`}
                    alt={"Articles"}
                    onClick={() => {
                      dispatchPlayAudio(item);
                    }}
                    item={item}
                  />
                ))
              }

            </div>
          </div>
        </div>
      </MainLayout>

      <Modal
        show={_isSharedMixDeleted}
        centered
        className="invite-success-modal success-modal"
        backdrop="static"
      >
        <Modal.Body>
          <div className="p-5 pt-2 px-4">
            <div className="text-end pb-3">
              <button className="closeButton notButton ms-auto" onClick={() => { setSharedMixDeleted(false); navigate("/home"); }}>
                <img src={crossIcon} alt="" />
              </button>
            </div>
            <h2 className="font-16 lh-16 my-5 fw-normal text-center">{errorMessageForMixture}</h2>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Home;
