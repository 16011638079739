import React, { useEffect, useRef, useState } from "react";

import { Menubar } from "./MenuBar";
import { Header } from "./Header";

import "./style.css";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import BASEURL from "../../Config/global";
import { FillPlay, BackButton, crossIcon, BackButtonNew } from "../../Assets/svg";
import { useNavigate } from "react-router-dom";
import { Placeholder } from "../../Assets/images";
import { hideUrlForAge, hideUrlForCountry } from "../../Util/global_functions";

const MainLayout = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [specialsCounter, setSpecialsCounter] = useState(0);
  const primaryInformation = useRef(null)
  const [informationData, setInformationData] = useState([]);
  const [showHideTray, setShowHideTray] = useState(false);
  const [desktopLogo, setDesktopLogo] = useState(Placeholder);
  const [headerLogo, setHeaderLogo] = useState(Placeholder);
  const [loader, setLoader] = useState(true);
  const userCountry = JSON.parse(localStorage.getItem("user_country"));


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };


  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`${BASEURL}/api/user/specials`);
        if (response.data.error != true) {
          setSpecialsCounter(response?.data?.data?.length);
        } else {
          console.log(response.data.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [])

  useEffect(() => {
    async function informationTray() {
      setLoader(true);
      const currentUser = localStorage.getItem("user");
      const parsedUser = JSON.parse(currentUser);
      let endPoint;
      if (parsedUser.status === "coupon" || parsedUser.status === "coupon_sub_user" || parsedUser.status === "coupon_lead") {
        endPoint = "coupon-user";
      }
      if (parsedUser.status === "primary" || parsedUser.status === "sub_user" || parsedUser.status === "lead") {
        endPoint = "subscription-user";
      }
      try {
        const response = await axios.get(`${BASEURL}/api/user/${endPoint}/${parsedUser.id}/`);
        // console.log(response.data.data)
        if (response.data.error != true) {
          const data = response.data.data?.[0]
          setInformationData(data);
          if (data?.header_logo_info) {
            setHeaderLogo(data?.header_logo_info);
          } else {
            setHeaderLogo(Placeholder);
          }
          if (data?.desktop_logo) {
            setDesktopLogo(data?.desktop_logo);
          } else {
            setDesktopLogo(Placeholder);
          }
          setLoader(false);
          // console.log(response.data)
          // console.log(desktopLogo)
        } else {
          setLoader(false);
          console.log(response.data.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
    informationTray();
  }, [])


  if (primaryInformation?.current != null) {
    primaryInformation.current.innerHTML = ''
    const div = document.createElement("div");
    primaryInformation?.current.appendChild(div);
    if (informationData?.html_info_text) {
      div?.insertAdjacentHTML("beforebegin", informationData?.html_info_text)
    }
  }
  const GoBack = () => {
    if (props?.backtoaccount) {
      const path = window.sessionStorage.getItem('path')
      if (path) {
        navigate(path)
      } else {
        navigate('/settings')
      }
    } else {
      if (props?.navigate) {
        navigate(`/${props?.navigate}`)
      } else {
        navigate('/home')
      }
    }
  }

  const menuClass = windowWidth < 1024 ? "mobileMenu" : "desktopMenu";
  return (
    <>
      <Menubar menuClass={menuClass} />
      <div className="secondaryBg">
        <div className="container-fluid">
          <div className="row g-0">
            <div className="col-12">
              <div
                className={`mainBody ${menuClass === "mobileMenu" ? "mobileBody" : "desktopBody"
                  }`}
              >
                <Header />
                {
                  props.title && (
                    <>
                      <div className="subHeader">
                        <div className={`${(!location.pathname.includes('home')) && 'mt-3 mb-2'} mainTitleBox`}>
                          {props.arrow ? (
                            <span className="backBtn rotate" onClick={GoBack}><img src={BackButtonNew} /></span>
                          ) : ''
                          }
                          <h1 className={`${(location.pathname.includes('home')) && 'd-none d-sm-block'} pageTitle`}>{props.title}</h1>
                        </div>
                        <div className="actionLinks">
                          {
                            hideUrlForAge() && hideUrlForCountry(userCountry) && (
                              <ul className="nav">
                                <li><Link to="/specials" className="ps-1"><span className="countBox">{specialsCounter}</span>Specials</Link></li>
                                <li><Link target="_blank" to="https://store.scofa.com/">Sleep Store</Link></li>
                                <li><Link target="_blank" to="https://scofa.com/search-for-sleep-provider/">Sleep Doctors</Link></li>
                              </ul>
                            )
                          }
                        </div>
                      </div>
                      {
                        (window.innerWidth <= 991 && location.pathname.includes('home'))&&  hideUrlForAge()  && (
                          <>
                            <div className="information-tray-card p-0">
                              {loader ? <div className="skeleton"></div>
                                : <div className={`information-tray-image ${showHideTray && 'show'}`} onClick={() => setShowHideTray(!showHideTray)}>
                                  <img src={desktopLogo} />
                                </div>
                              }
                            </div>
                            <div className={`information-tray d-flex flex-column ${showHideTray && 'show'}`}>
                              <div className="header position-relative">
                                <button
                                  className="closeButton notButton ms-auto d-flex p-3"
                                  onClick={() => { setShowHideTray(false) }}
                                >
                                  <img src={crossIcon} alt="" />
                                </button>
                                <img src={headerLogo} className="w-100 info-header-image" />
                              </div>
                              <div className="scrollbar">
                                <div className="p-3">
                                  {/* <h4 className="">{informationData?.primary_company_title}</h4> */}
                                  <div className="information-tray-data w-100" ref={primaryInformation}></div>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      }
                    </>
                  )
                }

                {props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <MainAudioPlayer menuClass={menuClass} /> */}
    </>
  );
};

export default MainLayout;
