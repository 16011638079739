import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import MainLayout from "./../../Layout/MainLayout";

import { currentUser } from "../../Config/data";

import "./style.css";
import { EditIcon, avatar } from "../../Assets/images";
import { Modal } from "react-bootstrap";
import { CrossIcon, PencilEdit, SaveShareMixEmailIcon, SaveShareMixLinkIcon, SaveShareMixWhatsappIcon, crossIcon } from "../../Assets/svg";
import { EmailIcon, EmailShareButton, FacebookMessengerIcon, FacebookMessengerShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import CustomInput from "../../Components/CustomInput";
import axios from "axios";
import BASEURL from "../../Config/global";
import CryptoJS from 'crypto-js';
import { userLogout } from "../../Store/Slices/APIs";
import { useDispatch } from "react-redux";
import BASEURLFrontend from "../../Config/urls";
import LinkCopiedModal from "../../Components/LinkCopiedModal";
import HouseHoldInviteModal from "../../Components/HouseHoldInviteModal";
import ProfileUpdated from "../../Components/ProfileUpdated";
import { hideUrlForAge } from "../../Util/global_functions";

const Settings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [updateProfile, setUpdateProfile] = useState(false);
  const [userData, setUserData] = useState([]);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [showProfileImage, setShowProfileImage] = useState(null);
  const [invalidField, setInvalidField] = useState(false);
  const [succesModal, setSuccesModal] = useState(false);
  const [copiedText, setCopiedText] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const [houseHoldEmailModal, setHouseHoldEmailModal] = useState(false);
  const [houseHoldEmailMsg, setHouseHoldEmailMsg] = useState({ error: false, text: '', type: '' });
  const [houseHoldSuccesModal, setHouseHoldSuccesModal] = useState(false);
  const [skeleton, setSkeleton] = useState(false);
  const userCountry = JSON.parse(localStorage.getItem("user_country"));

  const emailRef = useRef('');

  const userURLId = localStorage.getItem('userId');
  function randomString(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
  var string = randomString(4, 'abcdefghijklmnopqrstuvwxyz');

  useEffect(() => {
    dispatch(userLogout())
  }, [])

  useEffect(() => {

    const currentUser = JSON.parse(localStorage.getItem("user"));
    setUser(currentUser);
    getProfile(currentUser.id);
    encryptText(currentUser.id);


  }, []);


  const encryptText = (no) => {
    const key = 'secret-key'; // Replace with your secret key
    const id = no.toString()
    const encrypted = CryptoJS.AES.encrypt(id, key);
    const encryptedText = encrypted?.toString()?.replace(/\+/g, 'p1L2u3S').replace(/\//g, 's1L2a3S4h').replace(/=/g, 'e1Q2u3A4l');
    // setShareUrl(`https://relax.scofa.com/signup?id=${encryptedText}`);
    setShareUrl(`${BASEURLFrontend}/signup?id=${encryptedText}`);
  };

  const primaryKey = localStorage.getItem("is_primary");
  //  console.log(user)

  const editProfile = () => {
    navigate("/edit-profile");
    // setUpdateProfile(true);
  }

  const profileUpdated = async (e) => {
    e.preventDefault();
    setLoading(true);

    const currentUser = localStorage.getItem("user");
    let userId;
    if (currentUser) {
      const parsedUser = JSON.parse(currentUser);
      userId = parsedUser.id
    }

    const formDataToSend = new FormData();
    // if (profileImage != null) {
    //   formDataToSend.append("user_image", profileImage);
    // }
    formDataToSend.append("first_name", formData.fname);
    formDataToSend.append("last_name", formData.lname);
    formDataToSend.append("email", formData.email);
    // if(formData.password != undefined || formData.password != ''){
    //   formDataToSend.append("password", formData.password);
    // }
    if (formData.fname && formData.lname && formData.email) {
      try {
        const response = await axios.patch(
          `${BASEURL}/api/user/profile/${userId}`,
          formDataToSend
        );
        if (!response.data.error) {
          setLoading(false);
          setUpdateProfile(false);
          getProfile(userId);
          setInvalidField(false);
          setSuccesModal(true)
          setTimeout(() => {
            setSuccesModal(false)
          }, 1000);

        } else {
          setLoading(false);
          setInvalidField(false);
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      setInvalidField(true);
      setLoading(false)
    }

  }

  const handleProfileChange = (event) => {
    const file = event.target.files[0];
    setProfileImage(file);

    console.log(file)

    if (file === null || file === "undefined") {
      setShowProfileImage(null);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setShowProfileImage(reader.result);
        setFormData({
          ...formData,
          image: reader.result,
        });

        console.log(reader.result)
      };
    }
  };

  const getProfile = async (id) => {
    setSkeleton(true);
    const formDataToSend = new FormData();
    formDataToSend.append("email", formData.first_name);
    try {
      const response = await axios.get(
        `${BASEURL}/api/user/profile/${id}`
      );
      if (!response.data.error) {
        console.log(response?.data)
        setUserData(response?.data?.data);
        setFormData({
          image: response?.data?.data[0]?.user_image,
          fname: response?.data?.data[0]?.first_name,
          lname: response?.data?.data[0]?.last_name,
          email: response?.data?.data[0]?.email,
          // password: response?.data?.data[0]?.password,
        })
        setSkeleton(false);
      }
    } catch (error) {
      setSkeleton(false);
      console.log(error)
    }
  }

  const copyURL = () => {
    // console.log("---")
    var textField = document.createElement('textarea')
    textField.innerText = shareUrl
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    setCopiedText(true);
    textField.remove();
    setTimeout(() => {
      setCopiedText(false);
    }, 1000);
  }

  const houseHoldShare = async () => {
    setHouseHoldEmailMsg({ error: false, text: '' })
    if (emailRef.current.value.length > 0) {
      const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (pattern.test(emailRef.current.value)) {
        const formDataToSend = new FormData();
        formDataToSend.append("email", emailRef.current.value);
        formDataToSend.append("link", shareUrl);
        formDataToSend.append("user_id", user?.id);
        try {
          const response = await axios.post(
            `${BASEURL}/api/user/send_email_household/`,
            formDataToSend
          );
          if (!response.data.error) {
            setHouseHoldSuccesModal(true);
            setHouseHoldEmailModal(false);
            setTimeout(() => {
              setHouseHoldSuccesModal(false);
            }, 4000);
          } else {
            setHouseHoldEmailMsg({ error: true, text: 'Please Enter a valid Email' })
          }
        } catch (error) {
          console.log(error)
        }
      } else {
        setHouseHoldEmailMsg({ error: true, text: 'Please Enter a valid Email' })
      }
    } else {
      setHouseHoldEmailMsg({ error: true, text: 'Please Enter Your Email' })
    }
  }

  return (
    <>
      <MainLayout>
        <div className="row mb-lg-5 mb-4">
          <div className="col-12">
            <div className="d-flex align-items-center gap-3 justify-content-between px-3">
              {
                skeleton ?
                  <div className="d-flex align-items-center gap-3">
                    {/* <div class="avatar-skeleton"></div> */}
                    <div class="skeleton-loader"></div>
                  </div>
                  :
                  <div className="d-flex align-items-center gap-3">
                    {/* <img src={userData[0]?.user_image.includes("/no_image.png") ? avatar : userData[0]?.user_image} alt="" className="profileImage" /> */}
                    <h2 className="pageTitle">{`${userData[0]?.first_name} ${userData[0]?.last_name}`}</h2>
                  </div>
              }
              {/* <div class="card bg-transparent">
                <div class="cover-image-skeleton"></div>
                <div class="avatar-skeleton"></div>
                <div class="skeleton-loader"></div>
                <div class="skeleton-loader"></div>
                <div class="skeleton-loader"></div>
              </div> */}
              <div>
                <button onClick={editProfile} className="edit-profile-button text-white btn align-items-center d-flex">
                  <img src={EditIcon} alt="" className="profileImage me-2" />
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-0 g-lg-">
          <div className="col-12 px-0 px-lg">
            <div className="">
              {
                (user?.status === "primary" || user?.status === "coupon") && (
                  <div className="linkWrapper">
                    <Link to={"/account"} className="text-decoration-none fw-light">
                      Account Details
                    </Link>
                  </div>
                )
              }
              <div className="linkWrapper">
                <Link to={"/save-mixers"} className="text-decoration-none fw-light">
                  My Mixes
                </Link>
              </div>

              {
                (user?.offer_code_show && hideUrlForAge()) ? (
                  <div className="linkWrapper">
                    <Link to={"/share-earn"} className="text-decoration-none fw-light">
                      Share & Earn
                    </Link>
                  </div>
                ) : (
                  ''
                )
              }

              {
                ((user?.status === "primary" || user?.status === "coupon") && hideUrlForAge()) ? (
                  <div className="linkWrapper">
                    <Link to={"/household-member"} className="text-decoration-none fw-light">
                      Household Members
                    </Link>
                  </div>
                ) : (
                  ''
                )
              }

              {/* {
                (user?.status === "primary" || user?.status === "coupon") && (
                  <div className="linkWrapper">
                    <Link to="/household-member" className="text-decoration-none fw-light">
                      Household Members
                    </Link>
                  </div>
                )
              } */}

              {/* <div className="linkWrapper">
                <Link to={"#_"} className="text-decoration-none fw-light">
                  Member
                </Link>
              </div> */}

              {/* <div className="linkWrapper">
                <Link to={"#_"} className="text-decoration-none fw-light">
                  Devices
                </Link>
              </div> */}

              {/* <div className="linkWrapper">
                <Link to={"/privacy-policy"} className="text-decoration-none fw-light">
                  Privacy Policy
                </Link>
              </div>
              <div className="linkWrapper">
                <Link to={"/terms-of-service"} className="text-decoration-none fw-light">
                  Terms of Service
                </Link>
              </div> */}

              <div className="linkWrapper">
                <Link to={"/more-options"} className="text-decoration-none fw-light">
                  More Options
                </Link>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>

      <Modal
        show={showModal}
        centered
        className="soundModal mixer-modal"
        backdrop="static"
      >
        <Modal.Body>
          <div className="share-mix-wrapper d-flex flex-column">
            <div className="save-mix-header text-end">
              <button
                className="notButton ms-auto d-flex align-items-center p-4"
                onClick={() => setShowModal(false)}

              >
                <img src={crossIcon} alt="Close Button" className="img-fluid" style={{ width: '15px' }} />
              </button>
            </div>
            <div className="d-flex flex-column justify-content-between flex-grow-1">
              <div className="flex-shrink-0 mb-4">
                <h5 className="text-center fs-3">Invite Household Member</h5>
              </div>
              <div className="save-share-icon-wrapper flex-shrink-0 mb-5">
                <div className="d-flex align-items-center justify-content-center mb-3">
                  <div className="whatsapp-icon position-relative mx-1">
                    <WhatsappShareButton
                      url={shareUrl}
                      quote={'Title or jo bhi aapko likhna ho'}
                      hashtag={'#portfolio...'}
                      beforeOnClick={() => setShowModal(false)}
                    >
                      <WhatsappIcon size={50} round={true} />
                    </WhatsappShareButton>
                    <button
                      className="notButton"
                    >
                      <img src={SaveShareMixWhatsappIcon} alt="Close Button" style={{ width: '50px' }} />
                    </button>
                  </div>
                  <div className="messenger-icon position-relative mx-1">
                    {/* <EmailShareButton
                      url={shareUrl}
                      quote={'Title or jo bhi aapko likhna ho'}
                      hashtag={'#portfolio...'}
                      beforeOnClick={() => setShowModal(false)}
                    >
                      <EmailIcon size={50} round={true} />
                    </EmailShareButton> */}
                    <button className="notButton" onClick={() => { setHouseHoldEmailModal(true); setShowModal(false); }}>
                      <img src={SaveShareMixEmailIcon} alt="Close Button" style={{ width: '50px' }} />
                    </button>
                  </div>
                  <div className="copy-link-icon position-relative mx-1">
                    <button
                      className="notButton "
                      onClick={copyURL}
                    >
                      <img src={SaveShareMixLinkIcon} alt="Close Button" style={{ width: '50px' }} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={updateProfile}
        centered
        className="soundModal"
        backdrop="static"
      >
        <Modal.Body>
          <div className="text-end">
            <button
              className="closeButton notButton ms-auto"
              onClick={() => { setUpdateProfile(false); setLoading(false); setInvalidField(false); setShowProfileImage(null) }}
            >
              {/* <CrossIcon /> */}
              <img src={crossIcon} alt="" />
            </button>
          </div>
          <div className="d-flex flex-column align-items-center gap-3 justify-content-between">
            <div className="d-flex align-items-center gap-3">
              <input
                type="file"
                id="image"
                accept="image/*"
                className="d-none"
                required
                name="banner"
                onChange={handleProfileChange}
              />
              <label className="profile-image-wrapper" htmlFor="image">
                <img src={PencilEdit} alt="" className="profile-icon" />
                <img src={(formData?.image && formData?.image.includes("/no_image.png")) ? avatar : formData?.image} alt="" className="profileImage" />
                {/* <img src={showProfileImage != null ? showProfileImage : formData?.image ? BASEURL + formData?.image : avatar} alt="" className="profileImage" /> */}
              </label>
            </div>
            <div className="profile-form">
              <div className="inputWrapper">
                <label htmlFor="fname" className="mainLabel">
                  First Name
                </label>
                <input
                  className="mainInput"
                  id="fname"
                  type="text"
                  value={formData.fname}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      fname: event.target.value,
                    });
                  }}
                />
              </div>
              <div className="inputWrapper">
                <label htmlFor="lname" className="mainLabel">
                  Last Name
                </label>
                <input
                  className="mainInput"
                  id="lname"
                  type="text"
                  value={formData.lname}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      lname: event.target.value,
                    });
                  }}
                />
              </div>
              <div className="inputWrapper">
                <label htmlFor="email" className="mainLabel">
                  Email
                </label>
                <input
                  className="mainInput"
                  id="email"
                  type="email"
                  disabled
                  value={formData.email}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      email: event.target.value,
                    });
                  }}
                />
              </div>
              {/* <div className="inputWrapper">
                <label htmlFor="password" className="mainLabel">
                  Update Password
                </label>
                <CustomInput
                  inputClass="mainInput"
                  id="password"
                  type="password"
                  value={formData.password}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      password: event.target.value,
                    });
                  }}
                />
              </div> */}
              {invalidField && (
                <div className="col-12">
                  <p className="smallText lightColor">An error occurred. Please try again later.</p>
                </div>
              )}
              <button style={{ backgroundColor: '#ffffff1f' }} className='mb-3 px-5 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill d-flex align-items-center justify-content-center flex-grow-1 mx-auto' onClick={(e) => profileUpdated(e)}>
                {
                  loading ? <div className="spinner-border spinner-border-sm text-white" role="status"></div> : <span className=''>Update</span>
                }
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ProfileUpdated show={succesModal} />


      {/* copied modal */}
      <LinkCopiedModal copiedText={copiedText} />


      {/* copied modal */}
      <Modal
        show={houseHoldEmailModal}
        centered
        className="soundModal"
        backdrop="static"
      >
        <Modal.Body>
          <div className="text-end">
            <button
              className="closeButton notButton ms-auto"
              onClick={() => { setHouseHoldEmailModal(false); }}
            >
              {/* <CrossIcon /> */}
              <img src={crossIcon} alt="" />
            </button>
          </div>
          <div className="customModalContent mt-4">
            <h2 className="fs-6 fw-normal mb-3 ps-3">Email</h2>
            <div>
              <input type="email" required ref={emailRef} className="searchBar w-100" />
              {houseHoldEmailMsg.error && <small className="text-danger">{houseHoldEmailMsg.text}</small>}
            </div>
            <div className="mt-4 d-flex gap-3 justify-content-center mb-4">
              <button style={{ backgroundColor: '#ffffff1f' }} className='px-5 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill d-flex align-items-center justify-content-center flex-shrink-0' onClick={houseHoldShare}>
                <span className=''>Send</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <HouseHoldInviteModal houseHoldSuccesModal={houseHoldSuccesModal} />

    </>
  );
};

export default Settings;
