import React, { useState, useEffect } from 'react'
import { Tick } from "../../Assets/svg";

const CorporatePromo = ({ data, handleContinue }) => {

    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => setHeight(window.innerHeight);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <div className={`fullPageBg promotion-page-wrapper ${data?.page_name}`} style={{
                // backgroundImage: `url(${data?.bg})`
            }}>
                <div className="row g-0 align-items-center" style={{
                    // height: "100vh"
                }}>
                    {/* Plan 2 if bg is not working */}
                    <div className="col-md-6 d-none d-md-block">
                        {
                            (window.innerWidth < 1100 || (window.innerHeight > 900 && window.innerWidth <= 1366)) ? (
                                <img src={data?.img_tablet} alt={data?.img_tablet} className={`img-fluid advertise-img ${data?.page_name}-img`} />
                            ) : (
                                <>
                                    <img src={data?.img_tablet} alt={data?.img_tablet} className={`d-none d-md-block d-lg-none img-fluid advertise-img ${data?.page_name}-img`} />
                                    <img src={data?.img_1280} alt={data?.img_1280} className={`d-none d-lg-block d-xl-none img-fluid advertise-img ${data?.page_name}-img`} />
                                    <img src={data?.img_1366} alt={data?.img_1366} className={`d-none d-xl-block d-xxl-none img-fluid advertise-img ${data?.page_name}-img`} />
                                    <img src={data?.img_1920} alt={data?.img_1920} className={`d-none d-xxl-block img-fluid advertise-img ${data?.page_name}-img`} />
                                </>
                            )
                        }
                    </div>
                    <div className="col-12 col-md-6 mobile-height-add">
                        <div className="content-wrapper" style={{
                            maxHeight: `${height}px`,
                            overflow: 'auto'
                        }}>
                            <img src={data?.img_mob} alt={data?.img_mob} className="mobile-img img-fluid w-100 d-md-none" />
                            <div className="content-wrapper-inner">
                                <h2 className="sub-title mb-3 mb-md-4">{data?.text1}</h2>
                                <h4 className="sub-title-h4">{data?.text2}</h4>
                                <h4 className="sub-title-h4 mb-3 mb-md-4">{data?.text3} <br className="d-md-none" /> {data?.text4}</h4>
                                <h2 className="sub-title mb-1">{data?.text5}</h2>
                                <ListItem icon={Tick} data={data?.list || []} />
                                <h2 className="sub-title mt-3 mt-md-4 mb-1">{data?.text6}</h2>
                                <ListItem icon={Tick} data={data?.list_by_company || []} />
                                <h4 className="sub-title-h4 mt-3 mt-md-4">{data?.text7}</h4>
                                <h4 className="sub-title-h4">{data?.text8} <br className="d-md-none" /> {data?.text9}</h4>
                                <div className='mt-md-4 pt-md-2'>
                                    <button className="customButton continue-btn" onClick={() => handleContinue()}>Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};
const ListItem = ({ data, icon }) => {
    return (
        <ul className="listing-with-icon">
            {data?.map((item, index) => (
                <li className=" " key={index}>
                    <img src={icon} className="tick-style" />
                    <span className="">{item}</span>
                </li>
            ))}
        </ul>
    )
}


export default CorporatePromo