import { useEffect, useState } from "react";
import axios from "axios";
import WebRoutes from "./Routes";
import BASEURL from "./Config/global";
import { isMobileSafari, isSafari, isIOS } from 'react-device-detect'
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import "./Assets/css/style.css";
import "./Assets/css/multi.audio.player.css";
import { Modal } from "react-bootstrap";
import NoInternetConnection from "./Screens/NoInternetConnection";
import { setUserPrompts } from "./Store/Slices/UserSlice";
import { useDispatch } from "react-redux";

function useOnlineStatus() {
  const [online, setOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
    if (user) {
      getProfile(user.id);
    }
  }, []);

 

  

  const getProfile = async (id) => {
    try {
      const response = await axios.get(
        `${BASEURL}/api/user/profile/${id}`
      );
      if (!response.data.error) {
        if (response?.data?.data[0]) {
          const country = response?.data?.data[0].country
          localStorage.setItem('user_country', JSON.stringify(country))
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    function handleOnline() {
      setOnline(true);
    }

    function handleOffline() {
      setOnline(false);
    }

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Cleanup: Remove event listeners when the component unmounts
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return online;
}



function App() {
  // const [online, setOnline] = useState(window.navigator.onLine);
  const online = useOnlineStatus()
  const [ipAddress, setIPAddress] = useState('');
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  // state variable holds the state of the internet connection
  // const [isOnline, setOnline] = useState(true);

  // // On initization set the isOnline state.
  // useEffect(() => {
  //   setOnline(navigator.onLine)
  // }, [])

  // // event listeners to update the state 
  // window.addEventListener('online', () => {
  //   setOnline(true)
  // });

  // window.addEventListener('offline', () => {
  //   setOnline(false)
  // });
  useEffect(() => {
    fetchRelaxNotifcationKeys()
  }, [])

  async function fetchRelaxNotifcationKeys() {
    try {
      let url = `${BASEURL}/api/misc/keys/`;
      const response = await axios.get(url);
      if (!response.data.error) {
        let tranformReponse = {}
        const messages = response.data.data
        for (let index = 0; index < messages.length; index++) {
          const element = messages[index];
          tranformReponse = { ...tranformReponse, ...element }
        }
        dispatch(setUserPrompts(tranformReponse));
        setLoading(false)
      } else {
        console.log(response.data.message);
        setLoading(false)
      }

    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {

    const disableRightClick = (event) => {
      event.preventDefault();
    };

    // Ctrl + Shift + I || Ctrl + U (view source) || Disable F12
    const handleKeyDown = (event) => {
      if ((event.ctrlKey && event.shiftKey && event.keyCode === 73) || (event.ctrlKey && event.keyCode === 85) || event.keyCode === 123) {
        event.preventDefault();
      }
    };

    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    if (window.location.hostname != 'localhost') {
      document.body.addEventListener('contextmenu', disableRightClick);
      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('contextmenu', handleContextMenu);
    }

    return () => {
      if (window.location.hostname != 'localhost') {
        document.body.removeEventListener('contextmenu', disableRightClick);
        document.removeEventListener('keydown', handleKeyDown);
        document.removeEventListener('contextmenu', handleContextMenu);
      }
    };
  }, []);

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setIPAddress(data.ip))
      .catch(error => console.log(error))
  }, []);

  if (ipAddress !== '') {
    localStorage.setItem('ip', ipAddress)
  }
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="spinner-border text-primary" role="status"></div>
      </div>
    );
  }
  return (
    <>
      {
        online ? <WebRoutes /> : <NoInternetConnection />
      }
    </>
  );
}

export default App;
