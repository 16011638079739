import React, { useEffect, useState } from 'react'
import CustomInput from '../CustomInput'

const DateField = ({ selectedValue, formValue, label, defaultValidate = true }) => {

    const [_selectedDate, setSelectedDate] = useState("");

    useEffect(() => {
        console.log(formValue)
        if(formValue && formValue != undefined) {
            setSelectedDate(formValue);
        }else{
            setSelectedDate("");
        }
    }, [formValue])


    const handler = (event) => {
        const { name, value } = event.target;
        if (event.key === 'Backspace') {
            if (value.length == 3) {
                // setSelectedDate(value.slice(0, 2))
                selectedValue(value.slice(0, 2))
            } else if (value.length == 6) {
                // setSelectedDate(value.slice(0, 5))
                selectedValue(value.slice(0, 5))
            } else if (value.length <= 10 || value.length == '') {
                // setSelectedDate(value)
                selectedValue(value)
            }
        }
    };

    const changeMe = (e) => {
        const { name, value } = e.target;

        const sanitizedInput = value.replace(/[^0-9/]/g, '');
        const truncatedInput = sanitizedInput.slice(0, 10);
        const matches = truncatedInput.match(/^(\d{0,2})\/?(\d{0,2})\/?(\d{0,})$/);
        if (matches) {
            const [, month, day, year] = matches;
            const isValidMonth = month === '' || (parseInt(month, 10) <= 12);
            const isValidDay = day === '' || (parseInt(day, 10) <= 31);
            const isValidYear = year === '' || (!isNaN(year));
            if (isValidMonth && isValidDay && isValidYear) {
                const formattedInput = [month, day, year].filter(Boolean).join('/');
                // setSelectedDate(formattedInput)
                selectedValue(formattedInput)
            } else {
                console.log("Invalid date");
            }
        } else {
            console.log("Invalid date format");
        }
    };

    return (
        <CustomInput
            required={defaultValidate}
            label={label}
            id="date"
            type="text"
            placeholder="mm/dd/yyyy"
            labelClass="mainLabel"
            inputClass="mainInput place-holder-white"
            value={_selectedDate || ""}
            onChange={changeMe}
            onKeyDown={handler}
        />
    )
}

export default DateField