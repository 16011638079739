export const hideUrlForCountry = function (country) {
    switch (country?.toLowerCase()) {
        case 'us':
            console.log("country", country)
            return true
        case 'usa':
            return true
        case 'united states of america':
            return true
        case 'united states':
            return true
        case 'america':
            return true
        case 'u.s.a':
            return true
        default:
            return false
    }
}

export const hideUrlForAge = function (country) {
    const userData = JSON.parse(window.localStorage.getItem('user'));

    if (userData && userData.date_of_birth) {
        const birthDate = new Date(userData.date_of_birth);
        const today = new Date();

        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        const dayDiff = today.getDate() - birthDate.getDate();

        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            age--;
        }

        return age >= 18;
    }
    
    return false;
};

export const checkIsCouponUser = () => {
    const paths = ["promo", "promoint", "pedim", "qcm", "jsterling", "daniblifting", "sleep-care", "mhst"]
    let routePath = window.location.pathname?.toLowerCase().split("/")[1]
    let isCouponUser = paths.find(f=>f === routePath)
    return isCouponUser
}