import { useEffect, useState } from "react";
import { Daniblifting1280, Daniblifting1366, Daniblifting1920, DanibliftingMob, DanibliftingTablet, JSterling1280, JSterling1366, JSterling1920, JSterlingMob, JSterlingTablet, MHST1280, MHST1366, MHST1920, MHSTMob, MHSTTablet, Pedim1280, Pedim1366, Pedim1920, PedimMob, PedimTablet, QCM1280, QCM1366, QCM1920, QCMMob, QCMTablet, SleepCare1280, SleepCare1366, SleepCare1920, SleepCareMob, SleepCareTablet } from "../../Assets/images";
import Promo from "./Promo";
import CorporatePromo from "./CorporatePromo";
import Common from "../../Util/common";
import "./style.css";

const PromoPush = ({ handleContinueButton }) => {

    const [openedPageData, setOpenedPageData] = useState({});
    const [isCorporatePage, setIsCorporatePage] = useState(false);

    useEffect(() => {
        const path = window.location.pathname?.toLowerCase().split("/")[1]
        if (path != "promo") {
            document.querySelector("body")?.classList?.add("overflow-hidden");
        }
        switch (path) {
            case Common.PEDIM:
                setOpenedPageData(data?.pedim);
                setIsCorporatePage(true);
                break;
            case Common.QCM:
                setOpenedPageData(data?.qcm);
                setIsCorporatePage(true);
                break;
            case Common.JSTERLING:
                setOpenedPageData(data?.jsterling);
                setIsCorporatePage(true);
                break;
            case Common.DANIBLIFTING:
                setOpenedPageData(data?.danib_lifting);
                setIsCorporatePage(true);
                break;
            case Common.SLEEPCARE:
                setOpenedPageData(data?.sleep_care);
                setIsCorporatePage(true);
                break;
            case Common.MHST:
                setOpenedPageData(data?.mhst);
                setIsCorporatePage(true);
                break;
            default:
                break;
        }
    }, [])

    return (
        <>
            {
                isCorporatePage ? (
                    <CorporatePromo data={openedPageData} handleContinue={() => handleContinueButton()} />
                ) : (
                    <Promo data={openedPageData} handleContinue={() => handleContinueButton()} />
                )
            }
        </>
    );
};

export default PromoPush;


const data = {
    "pedim": {
        "text1": "Can’t Sleep? Can’t Relax?",
        "text2": "Waking up in the middle of the Night?",
        "text3": "Having Ruminating Thoughts?",
        "text4": "Falling Asleep During The Day?",
        "text5": "We are here to Help!",
        "text6": "Exclusive Offer for Pedim Patients!",
        "text7": "Start your hassle-free trial today – available only for Pedim patients!",
        "list": ["Sounds & Music", "Relaxing Meditations", "Curated Soundscape Mixes", "Guided Adult & Childrens Stories", "Sleep Medicine Articles & Guides"],
        "list_by_company": ["Enjoy a FREE 6-Month Trial – No credit card required!", "Lock in Savings After Your Trial – Get $20 OFF the regular price!", "Just $39.99/year (Regularly $59.99)"],
        "img_1920": Pedim1920,
        "img_1366": Pedim1366,
        "img_1280": Pedim1280,
        "img_tablet": PedimTablet,
        "img_mob": PedimMob,
        "bg": Pedim1920,
        "page_name": Common.PEDIM,
        "alignment_out": true
    },
    "qcm": {
       "text1": "Can’t Sleep? Can’t Relax?",
        "text2": "Waking up in the middle of the Night?",
        "text3": "Having Ruminating Thoughts?",
        "text4": "Falling Asleep During The Day?",
        "text5": "We are here to Help!",
        "text6": "Exclusive Offer for Quick Care Med Patients!",
        "text7": "Start your hassle-free trial today – available only for Quick Care Med patients!",
        "list": ["Sounds & Music", "Relaxing Meditations", "Curated Soundscape Mixes", "Guided Adult & Childrens Stories", "Sleep Medicine Articles & Guides"],
        "list_by_company": ["Enjoy a FREE 6-Month Trial – No credit card required!", "Lock in Savings After Your Trial – Get $20 OFF the regular price!", "Just $39.99/year (Regularly $59.99)"],
        "img_1920": QCM1920,
        "img_1366": QCM1366,
        "img_1280": QCM1280,
        "img_tablet": QCMTablet,
        "img_mob": QCMMob,
        "bg": QCM1920,
        "page_name": Common.QCM,
        "alignment_out": true
    },
    "danib_lifting": {
        "text1": "Can’t Sleep? Can’t Relax?",
        "text2": "Waking up in the middle of the Night?",
        "text3": "Having Ruminating Thoughts?",
        "text4": "Falling Asleep During The Day?",
        "text5": "We are here to Help!",
        "text6": "Relax & Enjoy a Peaceful Night's Sleep",
        "text7": "Waking up in the middle of the Night? ",
        "text8": "Having Ruminating Thoughts?",
        "text9": "Falling Asleep During The Day?",
        "text10": "Sub-accounts are  part of active Primary accounts",
        "list": ["Sounds & Music", "Meditations Breathing", "Guided Adult Stories & Children Stories", "Sleep Medicine Articles & Guides"],
        "img_1920": Daniblifting1920,
        "img_1366": Daniblifting1366,
        "img_1280": Daniblifting1280,
        "img_tablet": DanibliftingTablet,
        "img_mob": DanibliftingMob,
        "bg": Daniblifting1920,
        "page_name": Common.DANIBLIFTING,
        "alignment_out": false
    },
    "jsterling": {
        "text1": "Can’t Sleep? Can’t Relax?",
        "text2": "Waking up in the middle of the Night?",
        "text3": "Having Ruminating Thoughts?",
        "text4": "Falling Asleep During The Day?",
        "text5": "We are here to Help!",
        "text6": "Relax & Enjoy a Peaceful Night's Sleep",
        "text7": "Waking up in the middle of the Night? ",
        "text8": "Having Ruminating Thoughts?",
        "text9": "Falling Asleep During The Day?",
        "text10": "Sub-accounts are  part of active Primary accounts",
        "list": ["Sounds & Music", "Meditations Breathing", "Guided Adult Stories & Children Stories", "Sleep Medicine Articles & Guides"],
        "img_1920": JSterling1920,
        "img_1366": JSterling1366,
        "img_1280": JSterling1280,
        "img_tablet": JSterlingTablet,
        "img_mob": JSterlingMob,
        "bg": JSterling1920,
        "page_name": Common.JSTERLING
    },
    "mhst": {
        "text1": "Can’t Sleep? Can’t Relax?",
        "text2": "Waking up in the middle of the Night?",
        "text3": "Having Ruminating Thoughts?",
        "text4": "Falling Asleep During The Day?",
        "text5": "We are here to Help!",
        "text6": "Relax & Enjoy a Peaceful Night's Sleep",
        "text7": "Waking up in the middle of the Night? ",
        "text8": "Having Ruminating Thoughts?",
        "text9": "Falling Asleep During The Day?",
        "text10": "Sub-accounts are  part of active Primary accounts",
        "list": ["Sounds & Music", "Meditations Breathing", "Guided Adult Stories & Children Stories", "Sleep Medicine Articles & Guides"],
        "img_1920": MHST1920,
        "img_1366": MHST1366,
        "img_1280": MHST1280,
        "img_tablet": MHSTTablet,
        "img_mob": MHSTMob,
        "mobile_img": MHSTMob,
        "bg": MHST1920,
        "page_name": Common.MHST
    },
    "sleep_care": {
        "text1": "Can’t Sleep? Can’t Relax?",
        "text2": "Waking up in the middle of the Night?",
        "text3": "Having Ruminating Thoughts?",
        "text4": "Falling Asleep During The Day?",
        "text5": "We are here to Help!",
        "text6": "Relax & Enjoy a Peaceful Night's Sleep",
        "text7": "Waking up in the middle of the Night? ",
        "text8": "Having Ruminating Thoughts?",
        "text9": "Falling Asleep During The Day?",
        "text10": "Sub-accounts are  part of active Primary accounts",
        "list": ["Sounds & Music", "Meditations Breathing", "Guided Adult Stories & Children Stories", "Sleep Medicine Articles & Guides"],
        "img_1920": SleepCare1920,
        "img_1366": SleepCare1366,
        "img_1280": SleepCare1280,
        "img_tablet": SleepCareTablet,
        "img_mob": SleepCareMob,
        "mobile_img": SleepCareMob,
        "bg": SleepCare1920,
        "page_name": Common.SLEEPCARE
    },
}