import { React, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router'
import { getAccessToken, removeAccessToken } from "../../Util/authHeader";
import axios from "axios";
import BASEURL from "../../Config/global";
import MainLayout from "../../Layout/MainLayout";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { Modal } from 'react-bootstrap';
import './style.css'
import { crossIcon } from '../../Assets/svg';
import CustomButton from '../../Components/CustomButton';
import { setUserDetails } from '../../Store/Slices/UserSlice';
import { useDispatch, useSelector } from 'react-redux';
import PaymentDeclined from '../../Components/PaymentDeclined';

export const UpgradePayment = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userPrompts = useSelector((state) => state.user.userPrompts);

    const [paymentData, setPaymentData] = useState();
    const [succesModal, setSuccesModal] = useState(false);
    const [paymentDecline, setPaymentDecline] = useState(false);
    const [loading, setLoading] = useState(false);
    const [couponPaymentError, setCouponPaymentError] = useState({ error: false, text: "" });

    const stripe = useStripe()
    const elements = useElements()

    async function PackageDetails() {
        try {
            const response = await axios.get(`${BASEURL}/api/user/packages/${id}`);
            if (response.data.error != true) {
                setPaymentData(response?.data?.data[0])
            } else {
                console.log(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        PackageDetails()
    }, [])

    // payment form integration 

    async function signUpAPi() {
        setLoading(true);
        setCouponPaymentError({ error: false, text: "" });
        try {
            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
                return;
            }

            const cardElement = elements.getElement(CardElement);

            // Create a PaymentMethod and attach it to the customer
            const { paymentMethod, error } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });

            if (error) {
                setLoading(false);
                if (error.message.includes('Invalid API Key provided:')) {
                    setCouponPaymentError({ error: true, text: userPrompts?.invalid_card_details ||'Invalid Card Details' });
                } else {
                    setCouponPaymentError({ error: true, text: userPrompts?.invalid_card_details ||'Invalid Card Details' });
                    // setCouponPaymentError({ error: true, text: error.message });
                    console.error(error.message);
                    setPaymentDecline(true);
                    setTimeout(() => {
                        setPaymentDecline(false);
                    }, 2500);
                }
                // setCouponPaymentError({ error: true, text: error.message });
            } else {
                // const obj = {
                //     customerId: customerId,
                //     paymentMethodId: paymentMethod.id
                // }
                let parseData = JSON.parse(localStorage.getItem("user"));
                const userEmail = parseData.email;
                const productId = paymentData?.package_price_id;
                const planId = paymentData?.plan_id;

                const formData = new FormData();
                formData.append('email', userEmail);
                formData.append('plan_id', planId);
                formData.append('platform', "web");
                formData.append('paymentMethodId', paymentMethod.id);

                const response = await axios.post(`${BASEURL}/api/user/customersubscription/`, formData);
                // const customerId = response?.data?.customer_id;
                // const response = await axios.post(`${BASEURL}/api/user/attach_card`, obj);

                if (!response.data.error) {
                    setLoading(false);
                    setSuccesModal(true);
                    AfterPaymentApi();
                    setTimeout(() => {
                        setSuccesModal(false);
                        navigate('/upgrade-subscription')
                        handleLogout();
                    }, 2500);
                } else {
                    setLoading(false);
                    setCouponPaymentError({ error: true, text: userPrompts?.invalid_card_details ||'Invalid Card Details' });
                }
            }






        } catch (error) {
            console.error('Error:', error);
            // Handle the error appropriately, e.g., display an error message to the user.
        }
    }

    const handleLogout = () => {
        removeAccessToken();
        localStorage.removeItem("user");
        localStorage.removeItem("is_primary");
        localStorage.removeItem("user_email");
        localStorage.removeItem("ip");
        localStorage.removeItem("userId");
        dispatch(setUserDetails(null));
        window.location.reload(false)
    };

    const AfterPaymentApi = () => {
        let parseData = JSON.parse(localStorage.getItem("user"));
        const userId = parseData.id;
        const productId = paymentData?.id;
        console.log(productId)
        const formDataToSend = new FormData();
        formDataToSend.append("user_id", userId);
        formDataToSend.append("package_id", productId);
        try {
            const response = axios.post(
                `${BASEURL}/api/user/package_done`,
                formDataToSend
            );
        } catch (error) {
            console.error(error);
        }
    }

    // const handleSubmit = event => {
    //     event.preventDefault()
    //     signUpAPi()

    //     setTimeout(function () {
    //        
    //     }, 5000)
    // }

    return (
        <MainLayout>
            <div className="row flex-column">
                <div className='col-12'>
                    <div className='coupon-payment-wrapper p-4 text-center'>
                        <h2 className="pageTitle mb-5">Enter Payment Method</h2>
                        {/* <div className='packageFormInfo text-center'>
                            <h5 className='text-capitalize lightColor fs-4 mb-2'>{paymentData?.title}</h5>
                            <h5 className='lightColor fs-5 mb-2'>{`$ ${paymentData?.price}`}</h5>
                        </div>
                        <div className='paymentDescription text-center'>
                            <p className='fs-6 lightColor mb-2'>{paymentData?.description} (Free 14 Day Trial)</p>
                        </div> */}
                        <div className='cardBox my-4 mt-5'>
                            <CardElement />
                        </div>
                        {
                            couponPaymentError.error && <p className='my-2 text-danger'>{couponPaymentError.text}</p>
                        }
                        {
                            loading ? (
                                <div className='payNow text-center mt-4'>
                                    <button class="primaryButton customButton">
                                        <div class="spinner-border spinner-border-sm text-dark" role="status"></div>
                                    </button>
                                </div>
                            )
                                : (
                                    <div className='payNow text-center mt-4'>
                                        <CustomButton
                                            variant="primaryButton"
                                            text="Upgrade now"
                                            onClick={signUpAPi}
                                        />
                                    </div>
                                )
                        }
                    </div>
                </div>
            </div>
            <Modal
                show={succesModal}
                centered
                className="success-modal"
                backdrop="static"
            >
                <Modal.Body>
                    <div className="p-5">
                        <h2 className="font-16 lh-16 my-5 fw-normal text-center">{userPrompts?.subscription_updated_successfully || "Subscription Successfully Updated!"}</h2>
                    </div>
                </Modal.Body>
            </Modal>

            <PaymentDeclined paymentDecline={paymentDecline}/>

        </MainLayout>
    )
}
