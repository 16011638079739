import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { removeAccessToken } from "../../Util/authHeader";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetails } from "../../Store/Slices/UserSlice";

import { Dropdown, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import { avatar, logo } from "../../Assets/images";

import CustomSearchBar from "../../Components/CustomSearchBar";
import BASEURL from "../../Config/global";
import { appDownload, Cog, MyMixes, RefreshIcon, crossIcon, hamburgerMenu } from "../../Assets/svg";
import { userLogout } from "../../Store/Slices/APIs";
import AppDownloadTray from "../../Components/AppDownloadTray";
import { hideUrlForAge, hideUrlForCountry } from "../../Util/global_functions";

// import { currentUser } from "../../Config/data";

export const Header = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [logoutModal, setLogoutModal] = useState(false);
  const [succesModal, setSuccesModal] = useState(false);
  const [showAppDownloadTray, setShowAppDownloadTray] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const userCountry = JSON.parse(localStorage.getItem("user_country"));
  const userPrompts = useSelector((state) => state.user.userPrompts);

  useEffect(() => {
    // console.clear();
  }, []);


  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleLogout = () => {
    setLogoutModal(false);
    setSuccesModal(true);
    removeAccessToken();
    localStorage.removeItem("user");
    localStorage.removeItem("is_primary");
    localStorage.removeItem("user_email");
    localStorage.removeItem("ip");
    localStorage.removeItem("userId");
    dispatch(setUserDetails(null));
    setTimeout(() => {
      setSuccesModal(false)
      navigate("/login");
      window.location.reload(false)
    }, 500);
  };

  const hideAppDownloadTray = () => {
    setShowAppDownloadTray(false);
  }

  return (
    <>
      <div className="headerWrapper">
        <div className="row">
          <div className="col-12 text-end">
            <div className="d-flex align-items-center justify-content-lg-end justify-content-between">
              {/* <div className="headerSearchWrapper">
              <p className="smallText d-none d-md-block mb-0 me-2">Search</p>
              <CustomSearchBar
                placeholder="What do you want to listen to?"
                onChange={(event) => {
                  handleSearch(event);
                }}
              />
            </div> */}
              <Link className="logo d-block d-lg-none" to="/home">
                <img src={logo} alt="Logo" />
              </Link>
              <div className="d-flex align-items-center gap-1">
                {/* {
                  !user?.is_primary && <Link to={"/upgrade"} className="headerUpgradeLink d-none d-md-block pe-3">
                    Upgrade
                  </Link>
                } */}

                {/* <Link className="" to="/settings">
                <img src={Cog} alt="Cog" className="headerIcon" />
              </Link> */}



                <button
                  onClick={() => { setShowAppDownloadTray(true); }}
                  className="notButton d-flex d-lg-none align-items-center ps-2">
                  <img
                    src={appDownload}
                    style={{ filter: 'unset', width: '25px', height: '25px' }}
                    alt="saved mix"
                    className="playerActionIcon"
                  />
                </button>

                <button className="notButton d-flex align-items-center px-3">
                  <img
                    src={MyMixes}
                    style={{ filter: 'unset', width: '23px' }}
                    alt="saved mix"
                    className="playerActionIcon"
                    onClick={() => { navigate("/save-mixers"); }}
                  />
                </button>



                <Dropdown className="headerDropdown toggleHead">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="headerDropdownButton p-2"
                  >
                    {/* <img src={avatar} alt="Avatar" className="avatar" /> {user.first_name} */}
                    <img src={hamburgerMenu} alt="" className="p-1" width={30} height={30} />
                    {/* <img src={hamburgerMenu} alt="" className="p-1" width={30} /> */}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="headerDropdownMenu">
                    {/* {
                      !user?.is_primary && <Link
                        to={"/upgrade"}
                        className="headerDropdownAction"
                      >
                        Upgrade
                      </Link>
                    } */}
                    <Link to={"/save-mixers"} className="headerDropdownAction">
                      My Mixes
                    </Link>
                    {/* <Link to={""} className="headerDropdownAction">
                      Favorites
                    </Link> */}
                    {/* <Link to={""} className="headerDropdownAction">
                      My Playlist
                    </Link> */}
                    {/* <Link
                      to={"https://scofa.com/articles/"}
                      target="_blank"
                      className="headerDropdownAction"
                    >
                      Articles
                    </Link> */}

                    {
                     hideUrlForAge() && hideUrlForCountry(userCountry) && (
                        <>
                          <Link
                            to={"https://store.scofa.com/"}
                            target="_blank"
                            className="headerDropdownAction"
                          >
                            Sleep Store
                          </Link>
                          <Link
                            to={"https://scofa.com/search-for-sleep-provider/"}
                            target="_blank"
                            className="headerDropdownAction"
                          >
                            Sleep Doctors
                          </Link>
                          <Link
                            to={"https://scofa.com/check-sleep-disorder-symptoms/"}
                            target="_blank"
                            className="headerDropdownAction"
                          >
                            Check Symptoms
                          </Link>
                        </>
                      )
                    }


                    {/* <Link
                      to="/settings"
                      className="headerDropdownAction"
                    >
                      Profiles
                    </Link>

                    <Link
                      to="#"
                      className="headerDropdownAction"
                    >
                      Terms of Service
                    </Link>

                    <Link
                      to="#"
                      className="headerDropdownAction"
                    >
                      Feedback
                    </Link>

                    <Link
                      to="#"
                      className="headerDropdownAction"
                    >
                      FAQ
                    </Link>

                    <Link
                      to="#"
                      className="headerDropdownAction"
                    >
                      Support
                    </Link> */}


                  </Dropdown.Menu>
                </Dropdown>


                <Dropdown className="headerDropdown toggleHead">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="headerDropdownButton p-2"
                  >
                    <img src={Cog} alt="Cog" className="" width={30} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="headerDropdownMenu">
                    <Link
                      to={"/settings"}
                      className="headerDropdownAction"
                    >
                      Account
                    </Link>

                    {
                     ((user?.status === "primary" || user?.status === "coupon") && hideUrlForAge()) && (
                        <Link
                          to={"/household-member"}
                          className="headerDropdownAction"
                        >
                          Household
                        </Link>
                      )
                    }

                    {
                      (user?.offer_code_show && hideUrlForAge()) ? (
                        <Link to={"/share-earn"} className="headerDropdownAction">
                          Share & Earn
                        </Link>
                      ) : (
                        ''
                      )
                    }

                    {/* <Link
                      to={""}
                      className="headerDropdownAction"
                    >
                      Parental Controls
                    </Link> */}
                    <Link
                      to={"https://relaxscofa.com/faq/"}
                      className="headerDropdownAction"
                    >
                      FAQ
                    </Link>
                    <Link
                      to={"http://relaxscofa.com/support"}
                      className="headerDropdownAction"
                    >
                      Support
                    </Link>
                    {/* <Link
                      to={""}
                      className="headerDropdownAction"
                    >
                      Feedback
                    </Link> */}
                    <button
                      type="button"
                      className="headerDropdownAction"
                      onClick={() => setLogoutModal(true)}
                    >
                      Logout
                    </button>
                  </Dropdown.Menu>
                </Dropdown>

                <button className="notButton d-flex align-items-center px-2" onClick={() => { window.location.reload(false); dispatch(userLogout()) }}>
                  <img src={RefreshIcon} alt="" className="p-1" width={30} height={30} />
                  {/* <span className="text-white">Refresh</span> */}
                </button>


              </div>
            </div>
          </div>
        </div>
      </div>

      {/* logout modal */}
      <Modal
        show={logoutModal}
        centered
        className="soundModal"
        backdrop="static"
      >
        <Modal.Body>
          <div className="text-end">
            <button
              className="closeButton notButton ms-auto"
              onClick={() => setLogoutModal(false)}
            >
              <img src={crossIcon} alt="" />
            </button>
          </div>
          <div className="customModalContent my-5">
            <h2 className="font-16 lh-16 fw-normal mb-5 text-center">{userPrompts?.logout_message}</h2>
            <div className="d-flex align-items-center justify-content-center">
              <button style={{ backgroundColor: '#ffffff1f' }} className='mx-3 px-5 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill d-flex align-items-center justify-content-center flex-shrink-0' onClick={handleLogout}>
                <span className=''>Yes</span>
              </button>
              <button style={{ backgroundColor: '#ffffff1f' }} className='mx-3 px-5 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill d-flex align-items-center justify-content-center flex-shrink-0' onClick={() => setLogoutModal(false)}>
                <span className=''>No</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* success modal */}
      <Modal
        show={succesModal}
        centered
        className="success-modal"
        backdrop="static"
      >
        <Modal.Body>
          <div className="my-5">
            <h2 className="font-16 lh-16 fw-normal my-5 text-center">{userPrompts?.logout_message_notification}</h2>
          </div>
        </Modal.Body>
      </Modal>


      <AppDownloadTray openState={showAppDownloadTray} handleCallBack={hideAppDownloadTray} />
    </>
  );
};
