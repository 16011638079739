// Enum-like object
const Common = Object.freeze({
    FEATUREDMIXES: 'Featured Mixes',
    RELAXATION: 'relaxation',
    RELAXANDREST: 'Relax & Rest',
    RELAXREST: 'relax-rest',
    MIXURE: "mixture",
    JSTERLING: "jsterling",
    PEDIM: "pedim",
    QCM: "qcm",
    DANIBLIFTING: "daniblifting",
    MHST: "mhst",
    SLEEPCARE: "sleep-care",
});


export default Common;